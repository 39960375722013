@import "variables";

.ico {
  &--margin-left {
    margin-left: 0.8rem;
  }

  &--btn {
    line-height: 0;
    padding: 0.8rem;
    background: transparent;
    border-radius: $radius;
    @include transition(all 0.2s ease-in-out);

    svg, path {
      pointer-events: none;
    }

    &:hover {
      background: rgba($color_black, 0.1);
    }
  }
}
