@import "variables";

$brand_primary: $color_primary_dark;

// Text
$brand_text_dark: #0E0E0E;
$brand_text: #393939;
$brand_text_light: #676767;

// UI
$brand_ui_darkest: #070707;
$brand_ui_dark: #7B7B7B;
$brand_ui: #CCCCCC;
$brand_ui_light: #DDDDDD;
$brand_ui_lighter: #F3F3F3;
$brand_ui_lightest: #FFFFFF;

::-webkit-input-placeholder { /* Edge */
  color: $brand_text;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $brand_text;
}

::placeholder {
  color: $brand_text;
}

#email_submit_btn {
  transition: none !important;
}

textarea, input[type="text"], input[type="number"], input[type="button"], input[type="submit"], input[type="password"], input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  max-width: 100%;
}

label {
  display: inline-block;
  margin-bottom: 10px;
}

input {
  display: inline-block;
}

.form {
  display: flex;
  flex-direction: column;

  input {
    display: flex;
    flex: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 100%;
    padding: 17px 15px;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    font-size: 1.6rem;
    border-radius: $radius;
    border: 0;
    background: $color_white;
    box-shadow: 0 0 0 1px $brand_ui inset !important;
    transition: all 0.15s ease-in-out;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px $brand_primary inset !important;

      & + label {
        color: $brand_primary !important;
      }
    }
  }

  &__field__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    input {
      order: 2;
    }

    label {
      margin-bottom: 2px;
      order: 1;
      @include font-size(14, 20);
    }
  }

  &--is-loading {
    input {
      pointer-events:none;
    }

    .form__field__container {
      opacity: 0.6;
    }

    .btn {
      pointer-events:none;
      color: transparent;
      background-image: url('../images/preloader.gif');
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }
}

.field {
  position: relative;
  margin-bottom: 10px;

  &__label {
    display: none;
  }

  &.field--has-error {
    .field__label {
      color: $brand_error_dark;
    }
  }

  textarea, input[type="text"], input[type="email"], input[type="number"], input[type="button"], input[type="password"], input[type="search"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 100%;
    height: 50px;
    padding: 10px 15px;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    font-size: 1.6rem;
    border-radius: $radius;
    border: 0;
    background: $color_white;
    box-shadow: 0 0 0 1px $brand_ui inset !important;
    transition: all 0.15s ease-in-out;

    &:after {
      content: "text"; // TODO FED: is this used?
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px $brand_primary inset !important;

      & + label {
        color: $brand_primary !important;
      }
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  &:last-of-type {
    border-bottom: 0;
  }
}

.toggle {
  margin: 0 auto;
  background: $brand_ui_lighter;
  border-radius: $radius;
  min-height: 40px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  min-width: 250px;

  &--billing {
    max-width: 360px;
  }

  &__input {
    display: none;

    &:checked + .toggle__label {
      color: $brand_ui_lightest;
      background: $brand_primary;
      border-color: darken($brand_primary, 10%);
      border-right-width: 1px !important;

      &:hover {
        color: $brand_ui_lightest;
      }
    }
  }

  &__label {
    position: relative;
    flex: 1;
    display: flex;
    margin: 0;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: $brand_text_light;
    border: 1px solid $brand_ui_light;
    border-right-width: 0;

    .toggle__input:first-child + & {
      border-radius: $radius 0 0 $radius;
    }

    &:last-child {
      border-right-width: 1px;
      border-radius: 0 $radius $radius 0;
    }

    &:hover {
      color: darken($brand_text_light, 20%);
    }
  }
}

@include tablet {
  .form {

    &__field__container {
      flex: 1;

      &--btn {
        flex: 0;
        padding-right: 0;
      }
    }
  }
}
