@import "variables";

.alpaca_copy_notice {
  pointer-events: none;
  position: fixed;
  left: 50%;
  text-align: center;
  bottom: 10px;
  border-radius: 8px;
  padding: 5px 20px;
  background: rgba(0,0,0,0.8);
  color: white;
  font-size: 12px;
  opacity: 0;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transform: translateY(30px) translateX(-50%);
}
.alpaca_copy_notice.has-error {
  background: #A22525;
}
.alpaca_copy_notice.is-visible {
  opacity: 1;
  transform: translateY(0) translateX(-50%);
}

.support-card {
  border: 1px solid $color_border;
  border-radius: $radius;
  margin-bottom: 3.2rem;

  &__heading {
    margin: 1.6rem 0 0.8rem 0;
  }

  &__link {
    display: block;
    padding: 1.6rem;
    text-decoration: none;
    border-bottom: 1px solid $color_border;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__title {
    @include font-size(18, 28);
    margin-bottom: 0.4rem;
  }

  &__subtitle {
    @include font-size(16, 24);
    margin-bottom: 0.8rem;
  }

  &__date {
    color: $color_text_light;
    margin-bottom: 0;
    @include font-size(12, 16);
  }
}

.page--support {
  .container {
    max-width: 76.4rem !important;
  }
  .page__inner img {
    display: block;
    margin-top: 15px;
    margin-bottom: 30px;
    box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.1);
  }

  pre.highlight {
    margin: 0;
    border-radius: 3px 3px 0 0;
    transition: max-height 0.15s ease-in, padding 0.15s ease-in, opacity 0.15s ease-in;

    code {
      position: relative;
      display: block;
      max-height: 214px;
      overflow: hidden;

      &.has-overflow:before {
        position: absolute;
        text-align: center;
        height: 50px;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(242,242,242,0.5) 0%, darken($color_white, 5%) 100%);
        pointer-events: none;
      }
    }

    &--expanded {
      max-height: 0;
      overflow: auto;
    }
  }

  .copy-trigger {
    font-size: 12px;
    cursor: pointer;
    display: block;
    text-align: center;
    padding: 5px 20px;
    background: #fff;
    border: 1px solid $color_border;
    border-top: none;
    background: $color_white;
    border-radius: 0 0 3px 3px;
    margin-bottom: 20px;

    &:hover {
      background: darken($color_white, 2%);
    }
  }
}
