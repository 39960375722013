@import "variables";

$color_button: $color_white;

.btn {
  border: 2px solid $color_button;
  border-radius: $radius;
  background: $color_button;
  text-decoration: none;
  color: $color_brand_dark;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  @include font-size(14, 24);
  font-weight: 600;
  padding: 0.8rem 2rem;
  display: inline-block;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
  }

  &--secondary {
    background: transparent;
    color: $color_button;
    @include transition(all 0.15s ease-in-out)

    &:hover {
      background: $color_button;
      color: $color_brand_dark;
    }
  }

  &--light-bg {
    background: $color_primary_dark;
    color: $color_white;
    border-color: $color_primary_dark;
    @include transition(all 0.15s ease-in-out)

    &:hover {
      background: $color_primary_dark;
      color: $color_white;
    }
  }

  &--green {
    background: $color_green;
    color: $color_white;
    border-color: $color_green;
    @include transition(all 0.15s ease-in-out)

    &:hover {
      background: $color_green_dark;
      border-color: $color_green_dark;
      color: $color_white;
    }
  }

}

@include tablet {

  .btn {
    padding: 1.6rem 3.2rem;
  }
}
