// z-index
.video { z-index: 600; }
.mobile-nav { z-index: 590; }
.modal { z-index: 550; }
.overlay { z-index: 490; }

.is-hidden {
  display: none !important;
  pointer-events: none !important;
  visibility: hidden;
}
