@import "variables";
@import "base";
@import "buttons";
@import "components";
@import "forms";
@import "helpers";
@import "icons";
@import "modals";
@import "typography";
@import "support";

.container {
  padding: 0 2rem;

  &--relative {
    position: relative;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 1.6rem 0;

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
  }
}

.nav {
  &__ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__li {
    margin: 0 1.6rem 0 0;
    @include font-size(14, 20);
    text-transform: uppercase;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color_white;
    letter-spacing: 0.2rem;
    padding: 1.6rem;

    &.btn {
      padding: 1.6rem 3.2rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.app-badge {
  margin-top: 1.6rem;
  path {
    fill: $color_primary;
  }
}

.hero {
  text-align: center;
  padding: 16rem 0 8rem 0;

  &--slim {
    padding: 12rem 0 4rem 0;
  }

  &__h1 {
    color: $color_white;
    margin-bottom: 3.2rem;
  }

  &__subtitle {
    color: $color_secondary;
  }

  &__link {
    color: $color_secondary;
    font-size: 1.6rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__h1 + &__subtitle {
    position: relative;
    top: -2.4rem;
  }

  &__badge {
    margin-top: 3.2rem;
  }

  &__inner {
    max-width: 50rem;
    margin: 0 auto;
  }

  &__search {
    position: relative;
  }

  &__search-results {
    position: absolute;
    top: calc(100% + 0.8rem);
    border-radius: $radius;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0px);
    background: $color_white;
    border: 1px solid $color_border;
    font-size: 1.6rem;
    text-align: left;
    opacity: 1;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.2);
    text-align: center;
    @include transition(all 0.15s ease-in-out);
    list-style: none;

    &:empty {
      opacity: 0;
      transform: translate(-50%, 30px);
      pointer-events: none;
    }

    li {
      text-align: left;
      margin: 0;
      border-bottom: 1px solid $color_border;

      &:last-child {
        border-bottom: none;
      }
    }

    a {
      display: block;
      text-decoration: none;
      padding: 0.8rem 1.6rem;
    }
  }

  &__reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

}

.breadcrumbs {
  @include font-size(14, 20);
  margin-bottom: 3.2rem;
}

.page {
  @include font-size(18, 28);
  background: $color_secondary;
  padding: 6.4rem 0;

  .container {
    max-width: $max_inner_width;
    margin: 0 auto;
  }

  &__details {
    @include font-size(14,20);
    margin-bottom: 3.2rem;

    p {
      margin: 0;
    }
  }

  &__inner {
    p + h2, p + h3, p + h4, p + h5, p + h6 {
      margin-top: 3.2rem;
    }
  }
}

.image {
  text-align: center;
  background: linear-gradient(transparent 50%, $color_secondary 50%);

  &--reversed {
    background: linear-gradient($color_white 50%, transparent 50%);
  }

  &__inner {
    position: relative;
  }

  &__play_btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    @include transition(all 0.2s ease-in-out);

    svg, path {
      pointer-events: none;
    }

    &:hover {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1.02);
    }
  }
}

.logos {
  position: relative;
  background: $color_secondary;
  padding: 4rem 0;
  text-align: center;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  .logo {
    margin: 10px;

    picture, source, img {
      display: block;
      margin: 0;
      max-height: 50px;
    }
  }
}

.reviews {
  position: relative;
  background: $color_secondary;
  padding: 60px 0;

  &__heading {
    text-align: center;
    max-width: 70rem;
    margin: 0 auto 40px auto;
  }

  &__summary {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1000px;
  }
}

.review {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid lighten($color_border, 10%);
  border-radius: 8px;
  background: $color_white;

  &__rating {
    margin-bottom: 8px;

    svg {
      display: inline-block;
      margin: 0;
    }
  }

  &__description {
    @include font-size(16, 24);
    margin-bottom: 8px;
  }

  &__title {
    @include font-size(16, 24);
    font-weight: 700;
  }
}

.summary {
  position: relative;
  background: $color_secondary;
  border-top: 1px solid $color_border_dark;
  padding: 6.4rem 0;
  text-align: center;

  &__inner {
    max-width: 120rem;
    margin: 0 auto;
  }

  &__text {
    margin: 0 auto;
    max-width: 72rem;
    @include font-size(22, 40);
  }

  &__block {
    margin: 0 auto 6.4rem auto;
    max-width: 40rem;

    ul {
      list-style: none;
    }
  }

  &__li {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1.6rem;
  }

  &__li__ico {
    display: block;
    flex: 0 0 auto;
    margin: 0.8rem 1.6rem 0 0;
  }
}

.fulfillment {
  text-align: center;
  background: $color_white;
  padding: 6.4rem 0 0.8rem 0;

  &__graphic {
    transform: translate(0, 8rem);
  }

  &__inner {
    max-width: 70rem;
    margin: 0 auto;
  }
}

.secondary-cta {
  text-align: center;
  padding: 6.4rem 0;

  &__h2 {
    color: $color_white;
  }

  &__subtitle {
    color: $color_secondary;
  }

  &__btn {
    margin-bottom: 3.2rem;
  }
}

.footer {
  @include font-size(14, 20);
  background: $color_primary_dark;
  padding: 9.6rem 0 0.8rem 0;

  ul {
    list-style: none;
  }

  &__note {
    text-align: center;
    margin-top: 3.2rem;
    color: $color_white;
    @include font-size(13);
    text-transform: uppercase;

    a {
      color: $color_white;
    }
  }

  &__columns {
    margin: 0 auto 6.4rem auto;
    max-width: $max_inner_width;
    display: flex;
    flex-direction: column;
  }

  &__column {
    text-align: center;
    flex: 1;
    margin-bottom: 3.2rem;

    h3 {
      color: $color_white;
      @include font-size(13, 20);
      text-transform: uppercase;
      letter-spacing: 0.2rem;
    }

    li {
      margin-bottom: 0;
    }

    a {
      color: $color_white;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      text-decoration: none;
      display: inline-block;
      padding: 0.8rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.pricing {

  &__toggles {
    @include font-size(0);
    display: flex;
    margin-bottom: 3.2rem;
  }

  &__toggle {
    @include font-size(16);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid $color_border;
    padding: 1.6rem 0;
    text-decoration: none;
    background-color: darken($color_secondary, 4%);
    color: lighten($color_black, 70%);

    &:first-child {
      border-radius: $radius 0 0 $radius;
    }
    &:last-child {
      border-radius: 0 $radius $radius 0;
    }

    .pricing__savings {
      display: block;
      @include font-size(16);
    }

    &--selected {
      background-color: $color_secondary;
      border-color: $color_primary;
      color: $color_black;
    }
  }

  &__savings {
    color: $brand_success_color;
    pointer-events: none;
  }

  &__cost {
    font-weight: 400;
    @include font-size(30, 36);

    &--monthly {
      display: none;
    }
  }

  &__blocks {
    display: flex;
    flex-direction: column;

    &--monthly {

      .pricing__savings {
        display: none;
      }

      .pricing__cost--annually {
        display: none;
      }

      .pricing__cost--monthly {
        display: inline-block;
      }
    }
  }

  &__block {
    flex: 1;
    border-radius: $radius;
    padding: 1.6rem;
    border: 1px solid $color_border;
    margin-bottom: 3.2rem;

    &:only-child {
      margin: 0 auto 3.2rem auto !important;
      text-align: center;
    }

    &--custom {
      padding: 3.2rem 1.6rem;
    }
  }
}

.is-hidden--on-mobile-and-tablet {
  display: none;
}

@include mobile {
  .is-hidden--on-mobile {
    display: none;
  }
}

@include tablet {
  .is-hidden--above-tablet {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  .reviews {
    padding-top: 120px;
  }

  .review {
    width: calc(50% - 40px);
    margin: 20px;
  }

  .container {
    padding: 0 3.2rem;
  }

  .hero {

    &--slim {
      padding: 16rem 0 8rem 0;
    }

    &__inner {
      max-width: 72rem;
    }
  }

  .logos {
    &__inner {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  .summary {
    padding: 10rem 0 12rem 0;

    &__inner {
      display: flex;
      flex-direction: column;
    }

    &__block {
      flex: 1;
      margin-bottom: 6.4rem;
    }

    &__li {
      text-align: center;
      justify-content: center;
    }

    &__li__text {
      flex: 0 0 auto;
    }
  }

  .fulfillment {
    padding: 12rem 0 3.2rem 0;
  }

  .footer {
    &__columns {
      flex-direction: row;
    }
  }

  .pricing {
    &__toggle {
      @include font-size(18);
    }
  }
}

.feature {
  max-width: 96rem;
  margin: 0 auto;

  &__image {
    flex: 2;
  }
  &__description {
    flex: 3;
    text-align: left;
    font-size: 2.2rem;
    line-height: 3.6rem;
  }
}

@include tablet {
  .feature {
    &--alignment-right {
      .feature__image {
        order: 2;
      }
      .feature__description {
        order: 1;
        margin: 0 3rem 0 0;
      }
    }

    &__image {
      flex: 2;
    }
    &__description {
      flex: 3;
      margin: 0 0 0 3rem;
      font-size: 2.2rem;
      line-height: 3.6rem;
    }
  }
}

@include desktop {

  .hero {
    &__subtitle {
      color: $color_secondary;
      @include font-size(24, 36);
    }

    &__h1 + &__subtitle {
      position: relative;
      top: -1.6rem;
    }
  }

  .pricing {
    &__blocks {
      flex-direction: row;
    }

    &__block {
      margin-right: 3.2rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .is-hidden--on-mobile-and-tablet {
    display: block;

    &.nav__ul {
      display: flex;
    }
  }

  .is-visible--on-mobile-and-tablet {
    display: none;
  }
}

@include desktop-large {
  .app-badge {
    margin-top: 1.6rem;
    position: absolute;
    left: 1.6rem;
    top: 50%;
  }

  .summary {
    &__inner {
      display: flex;
      flex-direction: row;
    }

    &__block {
      margin: 0 1.6rem;
      max-width: none;
    }
  }
}
