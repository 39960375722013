@import "variables";

.modal {
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: $color_white;
  min-height: 100px;
  width: calc(100% - 20px);
  opacity: 0;
  pointer-events: none;
  max-width: 600px;
  @include transition(all 0.2s ease-in-out);
  display: block;
  pointer-events: all;
  opacity: 1;
  transform: translate(-50%,-50%) translate3d(0,0,0);
  transition: all 0.15s ease-in;

  &.is-hidden {
    transform: translate(-50%,-50%) translate3d(0,30px,0);
    display: block !important;
    opacity: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  &--full-screen {
    bottom: 10px;
    max-width: none;
  }

  &__inner {
    position: relative;
    padding: 20px;
    text-align: left;
  }

  &__close-action {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    opacity: 0.8;
    border-radius: $radius;
    display: block;
    margin: 0;
    font-size: 0;
    @include transition(background 0.2s ease-in-out);

    svg {
      pointer-events: none;
      display: block;
      margin: 0;
    }

    &:hover {
      opacity: 1;
      background: rgba($color_black, 0.1);
    }
  }
}

.overlay {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color_black, 0.6);
  pointer-events: all;
  display: block;
  opacity: 1;
  transform: translate3d(0,0,0);
  @include transition(all 0.15s ease-in-out);

  &.is-hidden {
    transform: translate3d(0,30px,0);
    display: block !important;
    opacity: 0;
  }
}

@include tablet {
  .modal {

    &--full-screen {
      top: 10px;
      left: 10px;
      transform: none;
    }

    &__inner {
      padding: 50px;
    }
  }
}
