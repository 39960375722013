@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  height: 100%;
  background: $color_primary;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  @include font-size(20, 32);
}

li {
  margin-bottom: 0.8rem;
}

img {
  max-width: 100%;
}

hr, .hr {
  height: 1px;
  border: 0;
  background: $color_border;
  margin: 6.4rem 0;
}

.hr--alt {
  background: $color_border_dark;
}

ol, ul {
  list-style-position: inside;

  ul, ol {
    margin-left: 2.4rem;
  }
}

pre {
  border: 1px solid $color_border;
  padding: 2rem;
  background: darken($color_white, 5%);
  white-space: pre-wrap;
  margin-bottom: 1.6rem;
  border-radius: 3px;
}

code {
  background: darken($color_white, 5%);
  border: 1px solid $color_border;
  padding: 2px 4px;
  border-radius: 3px;
}

pre code {
  border: 0;
  padding: 0;
  border-radius: 0;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section {
  background: $color_white;
  position: relative;
  padding: 6.4rem 0;
  text-align: center;

  &__heading {
    max-width: 70rem;
    margin: 0 auto;
    margin-bottom: 6rem;

    p {
      font-size: 2.2rem;
      line-height: 3.6rem;
    }
  }

  &__inner {
    max-width: 120rem;
    margin: 0 auto;
  }
}

@include tablet {
  .flex {
    flex-direction: row;
  }
  .section {
    padding: 10rem 0 12rem 0;
  }
}

@include desktop-large {

  .section {
    &__inner {
      display: flex;
      flex-direction: column;
    }
  }

}
