@import "variables";

* {
  color: $color_brand_dark;
}

h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 1.6rem;
}

h1, .h1 {
  @include font-size(36, 46);
}

h2, .h2 {
  @include font-size(30, 40);
}

h3, .h3 {
  @include font-size(24, 32);
}

h4, .h4 {
  @include font-size(20, 28);
}

h5, .h5 {
  @include font-size(16, 22);
}

h6, .h6 {
  @include font-size(13, 20);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: $color_text_subdued;
}

.small {
  @include font-size(15, 20);
}

.x-small {
  @include font-size(13, 20);
}

.center {
  text-align: center;
}

@include tablet {
  h1, .h1 {
    @include font-size(54, 72);
  }

  h2, .h2 {
    @include font-size(42, 56);
  }

  h3, .h3 {
    @include font-size(30, 40);
  }
}
