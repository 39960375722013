$color_primary: #A6935E;
$color_primary_dark: #8C7C4F;
$color_white: #FFFFFF;
$color_black: #000000;
$color_secondary: #F7F6F2;
$color_secondary_dark: #EBEAE6;
$color_green: #00825E;
$color_green_dark: #00664A;

$color_border: #D1D0CD;
$color_border_dark: #E7E5DF;

$color_text_subdued: #958E7A;
$color_text_light: #666666;

$color_brand_dark: $color_black;

// Error
$brand_error_dark: #C81E1E;
$brand_error: #DFA8A8;
$brand_error_light: #FCFAFA;

// Misc
$brand_success_color: #176A0F;
$brand_rating_color: #FFB22B;
$fb_blue: #3B5998;

$radius: 8px;
$form_shadow: inset 0 1px 4px 0 rgba($color_black,0.07);

/* Layout */
$large_container_width: 960px;
$small_container_width: 680px;
$navbar_height: 60px;

$max_inner_width: 1160px;

/* Responsive */
$tablet_min_width: 768px;
$desktop_min_width: 991px;
$desktop_large_min_width: 1200px;

@mixin font-size($size: 16, $line: $size * 1.4) {
  font-size: $size + px;
  font-size: ($size / 10) + rem;
  line-height: $line + px;
  line-height: ($line / 10) + rem;
}

@mixin mobile {
  @media (max-width: #{$tablet_min_width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet_min_width}) {
    @content;
  }
}

@mixin mobile-and-tablet-only {
  @media (max-width: #{$desktop_min_width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop_min_width}) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: #{$desktop_large_min_width}) {
    @content;
  }
}

@mixin scroll {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin ie {
  @media
    screen\0,
    (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    @content;
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin background-2x($path, $ext: "webp", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {

  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";

  background-image: url("#{$at1x_path}");
  background-size: $w $h;
  background-position: $pos;
  background-repeat: $repeat;

  @media all and (-webkit-min-device-pixel-ratio : 1.5),
  all and (-o-min-device-pixel-ratio: 3/2),
  all and (min--moz-device-pixel-ratio: 1.5),
  all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}");
  }
}
