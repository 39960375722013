@import "variables";

.mobile-nav {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $color_white;
  padding: 6.4rem 3.2rem 4rem 3.2rem;
  opacity: 1;
  transform: translate3d(0,0,0);
  transition: all 0.15s ease-in;

  &.is-hidden {
    transform: translate3d(0,30px,0);
    display: block !important;
    opacity: 0;
  }

  ul {
    list-style-type: none;
  }

  &__li {
    margin-bottom: 0;
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $color_brand_dark;
    text-decoration: none;
    padding: 0.8rem 0;
  }

  .btn--secondary {
    margin-top: 1.6rem;
    background-color: $color_primary_dark;
    color: $color_white;
  }

  .ico--dropdown {
    path {
      fill: $color_brand_dark;
    }
  }
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  //background: rgba($color_black, 0.6);
  opacity: 1;
  transform: translate3d(0,0,0);
  transition: all 0.15s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: $color_white;
  }

  &.is-hidden {
    transform: translate3d(0,30px,0);
    display: flex !important;
    opacity: 0;
  }
}

.youtube {
  background-color: #000;
  position: relative;
  padding-top: 45%;
  overflow: hidden;
  cursor: pointer;
  width: 80vw;
  margin: 0;
}
.youtube img {
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
}
.youtube img {
  cursor: pointer;
}
.youtube img,
.youtube iframe {
  position: absolute;
}
.youtube iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
